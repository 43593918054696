import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaFormFloatingGroupElement = class extends PolymerElement {
  static get is() {
    return 'jha-form-floating-group';
  }
  static get properties() {
    return {
      /** @type {?string} */
      error: {
        type: String,
        reflectToAttribute: true,
        notify: true,
      },
      hasValue: {
        type: Boolean,
        reflectToAttribute: true,
      },
      hasFocus: {
        type: Boolean,
        reflectToAttribute: true,
      },
      enableDefaultValidation: {
        type: Boolean,
        value: false,
      },
    };
  }

  constructor() {
    super();
    this.boundInputFocus = this.onInputFocus.bind(this);
    this.boundInputBlur = this.onInputBlur.bind(this);
    this.boundOnInput = this.onInput.bind(this);
    this.boundInputInvalid = this.onInputInvalid.bind(this);
    this.inputs = [];
    this.label = null;
  }

  connectedCallback() {
    super.connectedCallback();
    this.label = this.querySelector('.label');
    this.inputs = Array.from(this.querySelectorAll('input, textarea'));
    // we *should* only ever have one input,
    // but if not, we consider all of them.
    this.inputs.forEach((elem) => {
      // This event isn't triggered when the input's value
      // is changed programmatically. Any code that changes the value
      // should also dispatch the event.
      elem.addEventListener('focus', this.boundInputFocus, true);
      elem.addEventListener('blur', this.boundInputBlur, true);
      elem.addEventListener('input', this.boundOnInput, false);
      elem.addEventListener('change', this.boundOnInput, false);
      if (!this.enableDefaultValidation) {
        elem.addEventListener('invalid', this.boundInputInvalid);
      }

      const currentLabel = elem.getAttribute('aria-label');
      if (this.label && this.label.innerText && !currentLabel) {
        // sets an aria-label on the input, if there is not one already
        elem.setAttribute('aria-label', this.label.innerText);
      }
      // trigger an event in case input already has a value
      const event = document.createEvent('Event');
      event.initEvent('input', true, true);
      elem.dispatchEvent(event);
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.inputs.forEach((elem) => {
      elem.removeEventListener('focus', this.boundInputFocus, true);
      elem.removeEventListener('blur', this.boundInputBlur, true);
      elem.removeEventListener('input', this.boundOnInput, false);
      elem.removeEventListener('change', this.boundOnInput, false);
      if (!this.enableDefaultValidation) {
        elem.removeEventListener('invalid', this.boundInputInvalid);
      }
    });
  }

  /** @param {Event=} evt */
  onInputFocus(evt) {
    this.hasFocus = true;
  }

  /** @param {Event=} evt */
  onInputBlur(evt) {
    this.hasFocus = false;
  }

  /** @param {Event=} evt */
  onInput(evt) {
    this.hasValue = evt.currentTarget.value.trim().length  > 0;
    if (evt.target.nodeName === 'TEXTAREA') {
      evt.target.style.height = '5px';
      evt.target.style.height = (evt.target.scrollHeight) + 'px';
    }
  }

  /** @param {Event=} evt */
  onInputInvalid(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
        position: relative;
        padding-top: var(--jha-form-floating-group-padding-top, 12px);
        margin-bottom: var(--jha-form-floating-group-margin-bottom, 16px);
      }
      :host ::slotted(input),
      :host ::slotted(textarea) {
        font-family: inherit;
        display: block;
        width: 100%;
        height: 30px;
        padding: 6px 0 3px;
        border: 0;
        font-size: 14px;
        background-color: transparent;
        border-radius: 0;
        color: var(--jha-text-dark);
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        border-bottom: 1px solid var(--jha-border-color);
      }
      :host ::slotted(input[disabled]),
      :host ::slotted(textarea[disabled]),
      :host([outline]) ::slotted(textarea[disabled]),
      :host([outline]) ::slotted(input[disabled]) {
        color: var(--jha-form-floating-group-disabled-color, --jha-text-dark);
        border-color: var(--jha-form-floating-group-disabled-border-color, --jha-color-primary);
      }

      :host ::slotted(input:focus),
      :host ::slotted(textarea:focus) {
        outline: none;
        border-bottom: 1px solid var(--jha-color-primary);
      }

      :host ::slotted(input::placeholder),
      :host ::slotted(textarea::placeholder) {
        font-size: var(--jha-input-placeholder-size, 13px);
        color: var(--jha-input-placeholder-color, var(--jha-text-base, #707070));
        opacity: 1;
      }

      /* CSS to prevent zooming on iOS when input is focused */
      @supports (-webkit-overflow-scrolling: touch) {
        :host ::slotted(input),
        :host ::slotted(textarea) {
          font-size: 16px;
        }
      }
      :host ::slotted(textarea) {
        box-sizing: border-box;
        min-height: 38px;
        overflow: hidden;
        resize: none;
        height: auto;
        padding-top: 12px;
      }
      :host ::slotted(.label) {
        position: absolute;
        pointer-events: none;
        color: var(--jha-input-placeholder-color, var(--jha-text-base, #707070));
        font-size: var(--jha-form-floating-group-label-font-size, 14px);
        font-weight: 400;
        left: 0;
        top: 26px;
        -webkit-transition: all .2s cubic-bezier(.1,.5,.1,1);
        transition: all .2s cubic-bezier(.1,.5,.1,1);
        white-space: nowrap;
      }
      :host([has-value]) ::slotted(.label),
      :host([has-focus]) ::slotted(.label) {
        font-size: 12px;
        top: 5px;
      }
      :host([has-focus]) ::slotted(.label) {
        color: var(--jha-text-theme, #3aaeda);
      }
      :host([error]) ::slotted(input) {
        /* background-color: var(--color-danger); */
        border-color: var(--jha-color-danger, #f44336);
      }
      :host([error]) ::slotted(.label) {
        color: var(--jha-color-danger, #f44336);
      }
      :host([error]) .error {
        font-size: 12px;
        color: var(--jha-color-danger, #f44336);
      }
      :host([indent-error]) .error {
        margin-left: 10px;
      }
      .error {
        text-align: left;
        margin-top: 10px;
        line-height: 1.8;
        margin-bottom: 0;
      }
      p {
        white-space: pre-line;
      }
      p.error {
        margin-top: var(--jha-form-floating-group-error-padding-top, 10px);
        margin-left: var(--jha-form-floating-group-error-padding-left , 0);
      }
      :host([filled]) ::slotted(input),
      :host([filled]) ::slotted(textarea) {
        box-sizing: border-box;
        height: 50px;
        background: var(--jha-form-floating-group-filled-background, transparent);
        border-bottom: 1px solid var(--jha-form-floating-group-filled-border-color, var(--jha-border-color, #e4e7ea));
        font-size: 16px;
        padding: 12px 8px 0;
        border-radius: 4px 4px 0 0;
      }
      :host([filled]) ::slotted(textarea) {
        min-height: 48px;
        height: auto;
        padding: 24px 12px 8px;
      }
      :host([filled]) ::slotted(.label) {
        font-size: 16px;
        top: 30px;
        left: var(--jha-form-floating-group-label-left, 12px);
        color: var(--jha-input-placeholder-color, var(--jha-text-base, #707070));
      }
      :host([filled][has-value]) ::slotted(.label),
      :host([filled][has-focus]) ::slotted(.label) {
        font-size: 10px;
        top: 20px;
      }
      :host([filled][has-focus]) ::slotted(.label) {
        color: var(--jha-text-dark, #191919);
      }
      .underline {
        display: none;
      }
      :host([filled]) .underline {
        display: block;
        transform: scaleX(0);
        transition: transform .2s cubic-bezier(.1,.5,.1,1);
        margin-top: -2px;
        width: 100%;
        height: 2px;
        background: var(--jha-form-floating-group-underline-background, var(--jha-text-theme));
      }
      :host([filled][has-focus]) .underline {
        transform: scaleX(1);
      }
      :host([filled][error]) ::slotted(.label) {
        color: var(--jha-color-danger, #f44336);
      }
      :host([error]) .underline {
        background: var(--jha-color-danger, #f44336);
      }
      :host([filled][no-label]) {
        padding-top: 0;
        margin-bottom: 0;
      }

      :host([filled][no-label]) ::slotted(input) {
        height: 42px;
        font-size: 16px;
        padding: 10px 17px 10px;
      }
      :host([filled][no-label]) ::slotted(textarea) {
        min-height: 42px;
        height: 42px;
        padding: 14px 17px 10px;
      }
      :host([outline]) ::slotted(jha-select) {
        border: 1px transparent;
        border-radius: 4px;
        transition: all .2s ease;
      }
      :host([outline]) ::slotted(textarea),
      :host([outline]) ::slotted(input) {
        box-sizing: border-box;
        height: auto;
        border: 1px solid var(--jha-form-floating-group-outline-border-color, var(--jha-border-color, #8c989f));
        border-radius: 4px;
        padding: 18px 12px;
        transition: all .2s ease;
      }
      :host([outline]) ::slotted(jha-select[is-focused]),
      :host([outline][has-focus]) ::slotted(textarea),
      :host([outline][has-focus]) ::slotted(input) {
        border-color: var(--jha-text-theme, #3aaeda);
        box-shadow: 0 0 0 1px var(--jha-text-theme, #3aaeda);
      }
      :host([outline]) ::slotted(.label) {
        position: absolute;
        pointer-events: none;
        border-radius: 3px;
        font-size: var(--jha-form-floating-group-label-font-size, 16px);
        color: var(--jha-input-placeholder-color, var(--jha-text-base, #707070));
        background-color: var(--jha-component-background-color, #fff);
        font-weight: 400;
        padding: 3px 4px;
        transform-origin: 0 center;
        left: 8px;
        top: 28px;
      }
      :host([outline][has-focus]) ::slotted(.label) {
        color: var(--jha-text-theme, #3aaeda);
      }
      :host([outline][has-focus]) ::slotted(.label),
      :host([outline][has-value]) ::slotted(.label){
        transform: translateY(-28px) scale(.75);
      }
      :host([outline][small]) ::slotted(textarea),
      :host([outline][small]) ::slotted(input) {
        padding: var(--jha-form-group-outline-padding, 13px 12px);
      }
      :host([outline][small]) ::slotted(.label) {
        top: 21px;
      }
      :host([outline][small][has-focus]) ::slotted(.label),
      :host([outline][small][has-value]) ::slotted(.label){
        transform: translateY(-21px) scale(.75);
      }
      /* Edge specific styles */
      @supports (-ms-ime-align: auto) {
        :host ::slotted(.label) {
          top: 18px;
        }
        :host([has-value]) ::slotted(.label),
        :host([has-focus]) ::slotted(.label) {
          top: 0;
        }
        :host([outline][has-focus]) ::slotted(.label),
        :host([outline][has-value]) ::slotted(.label){
          top: 25px;
        }
      }
      /* IE 11 specific styles */
      @media all and (-ms-high-contrast:none) {
        :host ::slotted(.label) {
          top: 18px;
        }
        :host([has-value]) ::slotted(.label),
        :host([has-focus]) ::slotted(.label) {
          top: 0;
        }
        :host([outline][has-focus]) ::slotted(.label),
        :host([outline][has-value]) ::slotted(.label){
          top: 25px;
        }
      }
    </style>

    <slot></slot>
    <div class="underline">
    </div>

    <template is="dom-if" if="[[error]]">
      <p aria-live="polite" class="error">[[error]]</p>
    </template>
  `;
  }
};

customElements.define(window.JhaFormFloatingGroupElement.is, window.JhaFormFloatingGroupElement);
export default window.JhaFormFloatingGroupElement;
